/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import "@af-utils/scrollend-polyfill"; // as of 2025-01-09 safari still doesn't support scrollend event
import { RemixBrowser } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { startTransition, StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";
import { getRootLoaderDataFromWindow } from "./utils";

const rootLoader = getRootLoaderDataFromWindow();

Sentry.init({
  dsn: rootLoader.data?.sentryDSN,
  release: rootLoader.data?.sentryRelease,
  environment: rootLoader.data?.sentryEnvironment,
  integrations: [
    Sentry.browserTracingIntegration({
      enableHTTPTimings: true,
      instrumentPageLoad: true,
      traceFetch: true,
    }),
  ],
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1.0,
});

import("@sentry/remix").then((lazyLoaded) => {
  Sentry.addIntegration(lazyLoaded.replayIntegration());
  Sentry.addIntegration(lazyLoaded.extraErrorDataIntegration());
});

Sentry.setUser({
  id: rootLoader.data?.sentryUserId,
  username: rootLoader.data?.sentryUsername,
});

const originalFetch = window.fetch;
window.fetch = (input, init) => {
  if (input instanceof Request) {
    const newHeaders = new Headers(input.headers);
    if (!newHeaders.has("x-moneta-proxy-target")) {
      newHeaders.set("x-moneta-proxy-target", "remix");
    }
    const modifiedRequest = new Request(input, { headers: newHeaders });
    return originalFetch(modifiedRequest, init);
  } else {
    const modifiedOptions = init ?? {};
    modifiedOptions.headers = new Headers(modifiedOptions.headers);
    if (!modifiedOptions.headers.has("x-moneta-proxy-target")) {
      modifiedOptions.headers.set("x-moneta-proxy-target", "remix");
    }
    return originalFetch(input, modifiedOptions);
  }
};

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
