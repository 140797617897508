import { useNavigation } from "@remix-run/react";
import { LogOutIcon } from "lucide-react";
import { ReactNode } from "react";
import { useLogout } from "~/hooks";
import { cn, useRootLoaderData } from "~/utils";
import { EllipsisVertIcon } from "./icons";
import { IndeterminateProgress } from "./IndeterminateProgress";
import { MonetaStudioBrand } from "./MonetaStudioBrand";
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./ui";

export const DefaultNav = ({
  homeUrlOverride,
  onLogoClick,
  className,
  dropdownItems,
}: {
  homeUrlOverride?: string;
  onLogoClick?: () => void;
  className?: string;
  dropdownItems?: ReactNode;
}) => {
  const navigation = useNavigation();
  const { user, baseHomeUrl, release } = useRootLoaderData();
  const logout = useLogout();

  const homeUrl = homeUrlOverride ?? baseHomeUrl;
  const showLoader = navigation.state === "loading" || navigation.state === "submitting";

  return (
    <>
      {showLoader && (
        <div className="fixed top-0 w-full">
          <IndeterminateProgress />
        </div>
      )}
      <nav
        className={cn(
          "h-header flex shrink-0 items-center justify-between gap-4 px-2",
          // ensure that e.g. 404 hero doesn't cover the logo and link interactions
          "z-50",
          className,
        )}
      >
        <MonetaStudioBrand
          to={homeUrl}
          release={release}
          onClick={(e) => {
            if (onLogoClick) {
              e.preventDefault();
              onLogoClick();
            }
          }}
        />
        {user && (
          <div className="flex items-center gap-1">
            <span className="text-primaryText text-xl tracking-tight">{user.username}</span>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button icon type="button" variant="inverted" size="small">
                  <EllipsisVertIcon />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="min-w-[240px]">
                {dropdownItems}
                <DropdownMenuItem onSelect={() => logout()}>
                  <LogOutIcon className="mr-2 h-4 w-4" />
                  Logout
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </nav>
    </>
  );
};

export const DefaultLayout = ({ children, homeUrl: homeUrlOverride }: { children: ReactNode; homeUrl?: string }) => {
  return (
    <div className="flex h-full flex-col">
      <DefaultNav homeUrlOverride={homeUrlOverride} />
      {children}
    </div>
  );
};
